#nprogress {
    pointer-events: none;
    display: none;
}

#nprogress .bar {
    background: #4B2E83;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}

#nprogress .peg {
    display: none;
}

#nprogress .spinner {
    display: none;
}
